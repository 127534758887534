import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name : 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/logout',
    component: () => import('@/views/Logout.vue')
  },
  {
    path: '/selectUser',
    name : 'selectUser',
    component: () => import('@/views/SelectUser.vue')
  },
  {
    path: '/home',
    component: () => import('@/views/Notification.vue')
  },
  {
    path: '/hello',
    component: () => import('@/views/Hello.vue')
  },
  {
    path: '/notificationSearch',
    component: () => import('@/views/NotificationSearch.vue')
  },
  {
    path: '/stampRally',
    component: () => import('@/views/StampRally.vue')
  },
  {
    path: '/resetMission',
    component: () => import('@/views/ResetMission.vue')
  },
  {
    path : '/missionBoard',
    component: () => import('@/views/MissionBoard.vue')
  },
  {
    path : '/payment',
    component: () => import('@/views/Payment.vue')
  },
  {
    path : '/paymentProcedure',
    component: () => import('@/views/PaymentProcedure.vue')
  },
  {
    path : '/creditCardInput',
    name : '/creditCardInput',
    props:true,
    component: () => import('@/views/CreditCardInput.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//navigation guard
router.beforeEach((to, from, next) => {
  next();
})

export default router
