<template>
  <ion-app>
      <!-- メインcontents here -->
      <ion-router-outlet/>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"/>
  </ion-app>
</template>

<script>
import { IonApp,IonRouterOutlet,loadingController,} from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  created(){
    document.documentElement.setAttribute('lang', 'ja')
  },
    data(){
    return {
      "loading" : null
    }
  },
  methods : {
    async startLoading(){
      this.loading = await loadingController
        .create({
          message: '読み込み中...',
          translucent: true,
          backdropDismiss: false
        });
      this.loading.present();
    },
    async endLoading(){
      if(this.loading == null){
        this.startLoading();
      }
      this.loading.dismiss();
    }
  }
});
</script>
<style>
</style>