import { createApp } from 'vue'
// vuex
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
// default
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

// Above the createApp() line
import { defineCustomElements } from '@ionic/pwa-elements/loader';

//firebase
import firebase from 'firebase';

//animate.css
import 'animate.css'; 

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

  // Create a new store instance.
const store = createStore({
  state () {
    return {
      language : 'jp',
      users : [],
      activeUser : null,
      isLoading : false,
      loginTimestamp : null, //明示的にnull
    }
  },
  mutations: {
    setTenantId(state,tenantId){
      state.tenantId = tenantId;
    },
    setLanguage (state, language) {
      state.language = language;
    },
    setUsers(state,users){
      state.users = users;
    },
    setActiveUser(state,user){
      state.activeUser = user;
    },
    setLoading(state,loading){
      state.isLoading = loading;
    },
    setLoginTimestamp(state,timestamp){
      state.loginTimestamp = timestamp;
    },
    setModal(state,modal){
      state.modal = modal;
    },
    destroy(state){
      state.language = "jp";
      state.users = null;
      state.activeUser = null;
    },
    setMissions(state,missions){
      state.missions = missions;
    },
    resetMission(state){
      state.missions = null;
    },
    setAmount(state,amount){
      state.amount = amount;
    },
    setSelectedYYYYMM(state,selectedYYYYMM){
      state.selectedYYYYMM = selectedYYYYMM;
    },
    setPayment(state,payment){
      state.payment = payment;
    }
  },
  plugins: [createPersistedState()],
});

// firebase functionsの環境変数で設定でされているトーケン
const API_TOKEN = "AIzaSyC2p2pbh9qsNmgJ2yTZzVXIU3NczTGwWoI";
axios.interceptors.request.use(config=> {
    config.headers.Authorization =  API_TOKEN;
    return config;
});

const firebaseConfig = {
  apiKey: "AIzaSyCA3Pcn3mz_ofF8yWOSQjAumHG-7-0gaaQ",
  authDomain: "syaberu-6df32.firebaseapp.com",
  projectId: "syaberu-6df32",
  storageBucket: "syaberu-6df32.appspot.com",
  messagingSenderId: "391079913827",
  appId: "1:391079913827:web:8a163eb6f74db1574e8b56"
};
firebase.initializeApp(firebaseConfig);

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(VueAxios, axios);

router.isReady().then(() => {
  app.mount('#app');
});